
import './App.css';
import {IoMdMore} from "react-icons/io";
function App() {
  return (
    <div className="App">
     { /*
     ---------------------------------first row-------------------------------------
      ---------------------------------column 1--------------------------------------*/
      }
      <div className="Cont1">
        <div className="Cont11">
          <div ClassName="Line1" style={{width:'50px',height:'0px',left:'170px',top:'121px'}}>
          </div>
          <div className="Cont111">
          <div><IoMdMore size={40} color='#7d7d7d'/></div>
          </div>
          <span className="Txt121">
            LATEST TEST RUN 4 HOURS AGO
          </span>
          <p className="Txt122">
            Correctness%
          </p>
          <div className='Line1'></div>
          <div className='Txt123'>79</div>
          <div className="Txt123" style={{top:"120px",left:'245px'}}>OK</div>
          <div className='Line1' style={{left: '304.99px'}}></div>
          <div className='Txt122' style={{top: '160px'}}>confidence %</div>
          <div className='Line2' ></div>
          <div className='Txt123'style={{top: '160px'}}>91</div>
          <div className="Txt123" style={{top:'177px',left:'240px'}}>GOOD</div>
          <div className='Line2' style={{left: '304.99px'}}></div>
          <div className='Txt122' style={{top: '200px'}}>Clarity %</div>
          <div className="Txt123" style={{top:'220px',left:'240px'}}>GREAT</div>
          <div className='Line3' ></div>
          <div className='Txt123'style={{top: '200px'}}>96</div>
          <div className='Line3' style={{left: '304.99px'}}></div>
       </div>
        <p className="Txt11">
          Chatbot v2.6
        </p>
        <p className="Txt112">
          17 tests
        </p>
      </div>
      {/*
      --------------------------------------column 2-------------------------------------------------------*/
    }
      <div className="Cont2">
      <div className="Cont111" style={{left:'437px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
        <div className="Cont22">
          <div className="Cont221" >
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Correctness %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>61</div>
          <div className='Line4' ></div>
          <div className="Txt22" style={{left:'29px',top:'65px',width: '300px'}}>OK</div>
          <div className="Txt22" style={{left:'154px',top:'5px',width: '300px'}}>Confidence %</div>
          <div className="Txt22" style={{left:'180px',top:'40px',width: '300px'}}>62</div>
          <div className='Line4' style={{left:'170px',top:'60px'}}></div>
          <div className="Txt22" style={{left:'180px',top:'65px',width: '300px'}}>OK</div>
          </div>
          <div className="Cont222">
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Clarity %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>82</div>
          <div className='Line4' style={{border:'1px solid #a0d90b'}}></div>
          <div className="Txt22" style={{left:'25px',top:'65px',width: '300px'}}>GOOD</div>
          </div>
        </div>
        <div className='Txt21'>Subjet matter test</div>
        <div className="Txt22">9 tests</div>
      
      </div>
      <div className='ContMid2' style={{left:'952px' ,top:'180px'}}></div>
      <div className="Txt22" style={{left:'959px',top:'195px',width: '300px'}}>LATEST TEST RUN -6 HOURS AGO</div>
   { 
   /*------------------------------------------------row 2-------------------------------------------
    ----------------------------------------------- column 1---------------------------------------
   */}
      <div className="Cont3">
      <div className="Cont111"style={{left:'401px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
      <div className="Cont22" style={{width:'440px'}}>
          <div className="Cont221" style={{width:'146px'}}>
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Correctness %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>91</div>
          <div className='Line4' style={{border: '1px solid #5e682e'}}></div>
          <div className="Txt22" style={{left:'29px',top:'65px',width: '300px'}}>GOOD</div>
          <div className="Txt22" style={{left:'154px',top:'5px',width: '300px'}}>Confidence %</div>
          <div className="Txt22" style={{left:'180px',top:'40px',width: '300px'}}>96</div>
          <div className='Line4' style={{left:'170px',top:'60px',border: '1px solid #08eba3'}}></div>
          <div className="Txt22" style={{left:'180px',top:'65px',width: '300px'}}>GREAT</div>
          </div>
          <div className="Cont222"style={{left:'292px',width:'147px'}}>
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Clarity %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>99</div>
          <div className='Line4' style={{border: '1px solid #08eba3'}}></div>
          <div className="Txt22" style={{left:'25px',top:'65px',width: '300px'}}>GREAT</div>
          </div>
        </div>
        <div className='Txt21'>January updates v0.3</div>
        <div className="Txt22" style={{width:'120px'}}>16 tests</div>
      
      </div>
      <div className='ContMid2' style={{left:'15px' ,top:'515px',width:'440px'}}></div>
      <div className="Txt22" style={{left:'20px',top:'525px',width: '300px'}}>LATEST TEST RUN -6 HOURS AGO</div>

     {/*
      --------------------------------------column 2-------------------------------------------------------*/
    }
      <div className="Cont4">
      <div className="Cont111"style={{left:'401px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
      <div className="Cont22" style={{width:'440px'}}>
          <div className="Cont221" style={{width:'146px'}}>
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Correctness %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>35</div>
          <div className='Line4' style={{border: '1px solid #ff0000'}}></div>
          <div className="Txt22" style={{left:'29px',top:'65px',width: '300px'}}>BAD</div>
          <div className="Txt22" style={{left:'154px',top:'5px',width: '300px'}}>Confidence %</div>
          <div className="Txt22" style={{left:'180px',top:'40px',width: '300px'}}>32</div>
          <div className='Line4' style={{left:'170px',top:'60px',border: '1px solid #ff0000'}}></div>
          <div className="Txt22" style={{left:'180px',top:'65px',width: '300px'}}>BAD</div>
          </div>
          <div className="Cont222"style={{left:'292px',width:'147px'}}>
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Clarity %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>40</div>
          <div className='Line4' style={{border: '1px solid #ff0000'}}></div>
          <div className="Txt22" style={{left:'25px',top:'65px',width: '300px'}}>BAD</div>
          </div>
        </div>
        <div className='Txt21'>Regression testing</div>
        <div className="Txt22">2 tests</div>
      
      </div>
      <div className='ContMid2' style={{left:'470px' ,top:'515px',width:'440px'}}></div>
      <div className="Txt22" style={{left:'475px',top:'525px',width: '300px'}}>LATEST TEST RUN -6 HOURS AGO</div>
      {/*
      --------------------------------------column 3-------------------------------------------------------*/
    }
      <div className="Cont5">
        <div className="Cont51">
          
        </div>
        <div className="Txt21" style={{left:'33px',top:'14px',fontSize:'13px'}}>New algorithm </div>
      <div className="Cont111"style={{left:'433px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
      <div className="Cont22" style={{width:'469px'}}>
          <div className="Cont221" style={{width:'156px'}}>
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Correctness %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>60</div>
          <div className='Line4' ></div>
          <div className="Txt22" style={{left:'29px',top:'65px',width: '300px'}}>OK</div>
          <div className="Txt22" style={{left:'154px',top:'5px',width: '300px'}}>Confidence %</div>
          <div className="Txt22" style={{left:'180px',top:'40px',width: '300px'}}>82</div>
          <div className='Line4' style={{left:'170px',top:'60px',border:'1px solid #a0d90b'}}></div>
          <div className="Txt22" style={{left:'180px',top:'65px',width: '300px'}}>GOOD</div>
          </div>
          <div className="Cont222"style={{left:'312px',width:'157px'}}>
          <div className="Txt22" style={{left:'4px',top:'5px',width: '300px'}}>Clarity %</div>
          <div className="Txt22" style={{left:'29px',top:'40px',width: '300px'}}>62</div>
          <div className='Line4' ></div>
          <div className="Txt22" style={{left:'25px',top:'65px',width: '300px'}}>OK</div>
          </div>
        </div>
        <div className='Txt21'>Chatbot v2.5</div>
        <div className="Txt22">1 test</div>
      
      </div>
      <div className='ContMid2' style={{left:'952px' ,top:'515px',width:'470px'}}></div>
      <div className="Txt22" style={{left:'957px',top:'525px',width: '300px'}}>LATEST TEST RUN -6 HOURS AGO</div>
     { 
     /*-----------------------------------------row 3-----------------------------------------------------
      ----------------------------------------column 1-----------------------------------------------------*/
    }
      <div className="Cont6">
      <div className="Cont111"style={{left:'401px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
        <div className='Txt21'>December updates</div>
        <div className="Txt22"style={{width:'120px'}}>35 tests</div>
      </div>
      {/*
      --------------------------------------column 2-------------------------------------------------------*/
    }
      <div className="Cont7">
      <div className="Cont111"style={{left:'401px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
        <div className='Txt21'style={{width:'270px'}}>Expiremental test data</div>
        <div className="Txt22">2 tests</div>
      </div>
      {/*
      --------------------------------------column 3-------------------------------------------------------*/
      }
      <div className="Cont8">
      <div className="Cont111"style={{left:'433px'}}>
      <div><IoMdMore size={40} color='#7d7d7d'/></div>
      </div>
        <div className='Txt21'>Chatbot v2.4</div>
        <div className="Txt22">1 tests</div>
      </div>
    </div>
  );
}

export default App;
